import store from '@/store';
import { ProfileInterface } from '@/interfaces/ProfileStateInterface';

export default class ReportRowColorService {
    public static getRowColor(license: any): string {
        if (! license.authorized && ! license.inUse) {
            return '';
        }

        if (! license.authorized) {
            return 'red';
        }

        if (Number(license.configured) > Number(license.authorizedUsage)) {
            return 'red';
        }

        const profile: ProfileInterface = store.getters['profile/getProfile'];

        if (profile.subscribed && license.type === 'Capacity') {
            const coeff: number = this.getCoefficient(license.name);

            // Tape and cloud
            if (['tape', 'totalCloud'].includes(license.name)
                && (Number(license.configured) > (Number(license.authorizedUsage) * (coeff / 100)))
            ) {
                return 'amber';
            }

            // Fs
            if (license.name === 'fs' && Number(license.usage) > (Number(license.configured) * (coeff / 100))) {
                return 'amber';
            }
        }

        return '';
    }

    private static getCoefficient(licenseName: string): number {
        switch (licenseName) {
            case 'fs':
                return store.getters['licenseModule/getConfiguredCoeff'];
            case 'tape':
                return store.getters['licenseModule/getAuthorizedCoeff'];
            case 'totalCloud':
                return store.getters['licenseModule/getAuthorizedCoeff'];
            default:
                return 1;
        }
    }
}
