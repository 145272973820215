export default class UsersService {
    public static getHumanReadableRoles(roles: string[]): string {
        roles = roles.map((role: string) => {
            switch (role) {
                case 'ROLE_ADMIN':
                    return 'Admin';
                case 'ROLE_USER':
                    return 'User';
                case 'ROLE_LICENSE_ADMIN':
                    return 'License Admin';
                default:
                    return '';
            }
        });

        return roles.join(', ');
    }
}
