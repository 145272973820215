import moment from 'moment-timezone';

export default class DateUtil {
    /**
     * @param {number} milliseconds
     * @param {string} format
     * @param {string} tz
     */
    public static millisecondsToDateFormat(milliseconds: number, format: string, tz: string = ''): string {
        const date = moment(milliseconds);

        if (tz) {
            date.tz(tz);
        }

        return date.format(format);
    }

    /**
     * @param {string} dateStr
     * @param {string} format
     * @param {string} tz
     */
    public static dateToFormat(dateStr: string, format: string, tz: string = ''): string {
        const date = moment(dateStr);

        if (tz) {
            date.tz(tz);
        }

        return date.format(format);
    }

    /**
     *
     * @param firstDate
     * @param secondDate
     */
    public static diffInHours(firstDate: number, secondDate: number): number {
        return Math.floor((firstDate - secondDate) / 3600000);
    }

    /**
     *
     * @param {string} date
     */
    public static dateToMilliseconds(date: string): number {
        return moment(date).valueOf();
    }
}
