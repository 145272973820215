export const COMPLIANCE_ALERT = 'Your use or scope of use of the StorNext Software on this system is out of ' +
    'compliance with the authorized use and the StorNext Software End User License Agreement (EULA) until you ' +
    'have purchased the necessary StorNext Software License, as defined in the EULA, and entered the product key. ';

export const IS_PRODUCT_KEY = 'Your use or scope of use of the StorNext Software on this system is out of compliance ' +
    'with the authorized use and the StorNext Software End User License Agreement (EULA) until you have purchased the' +
    ' necessary StorNext Software License, as defined in the EULA.';

export const APPROACHING_TAPE_AND_CLOUD = '<p>Your use or scope of use of the StorNext Software on one or more ' +
    'systems is approaching out of compliance with the\n' +
    'authorized use and the StorNext Software End User License Agreement (EULA).' +
    '\n' +
    'This notification is for informational purposes. ' +
    'Your systems are within compliance based on the current authorized capacity licences. </p>';

export const APPROACHING_FS = '<p>The filesystem usage on one or more of your systems are approaching configured ' +
    'capacity. This notification is for informational purposes. Your systems are within compliance based' +
    ' on the current authorized capacity licences. </p>';
