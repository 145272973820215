<template lang='pug'>
    div
        v-navigation-drawer(v-on:input='drawerGo', temporary='', absolute='', :value='menu')
            v-toolbar(text='')
                v-list
                    v-list-item
                        v-list-item-title.title
                            | Quantum
            v-divider
            v-list.pt-0(dense='')
                v-list-item(:to="{name: 'home'}")
                    v-list-item-content
                    | Home
                v-list-item(:to="{name: 'logout'}")
                    v-list-item-content
                    | Logout

</template>

<script>
export default {
    name: 'MainMenu',

    props: {drawer: {type: Boolean}},
    data: () => ({
        menu: false,
    }),
    watch: {
        drawer() {
            /* As the drawer property changes, toggle the menu */
            this.menu = !this.menu;
        },
    },
    methods: {
        drawerGo(drawerEvent) {
            /* Since the drawer is temporary, we have to watch for it changing and set menu when the user clicks
                out of it */
            this.menu = drawerEvent;
        },
    },
};
</script>

<style scoped>

</style>
