


























import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
class ProgressSpinner extends Vue {
    @Prop()
    public readonly message?: string;
    @Prop()
    public readonly hideOverlay?: boolean;
}
export default ProgressSpinner;
