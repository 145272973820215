/*
 * Copyright 2019. Quantum Corporation. All Rights Reserved.
 * StorNext is either a trademark or registered trademark of
 * Quantum Corporation in the US and/or other countries.
 *
 */

import axios, {AxiosRequestConfig} from 'axios';
import router from '@/router';
import {store} from '@/store';

export default {
    create: (options?: AxiosRequestConfig|undefined) => {
        const ax = axios.create(options);
        ax.interceptors.response.use(x => x, (err: any) => new Promise(() => {
            if (err.response.status === 401) {
                if (err.config.url !== store.getters['siteConfigState/getBackend'] + '/login_check') {
                    store.commit('notificationState/clearSpinnerMessage');
                    store.commit('notificationState/setErrorMessage', 'Session expired. Please log back in');
                    router.push('logout');
                    return null;
                    // Return reject('Session expired. Please log back in');
                } else {
                    throw err;
                }
            } else {
                throw err;
            }
        }));
        return ax;
    },
};
