/*
 * Copyright 2019. Quantum Corporation. All Rights Reserved.
 * StorNext is either a trademark or registered trademark of
 * Quantum Corporation in the US and/or other countries.
 *
 */

import {BrowserConfigState} from '@/types';
import {ActionTree} from 'vuex';
import Vue from 'vue';

// Colors are based on Quantum Style Guide
const setThemeColors = (dark: boolean) => {
    // If (dark) {
    //     Vue.prototype.$vuetify.theme.primary = '#44befd';
    //     Vue.prototype.$vuetify.theme.accent = '#FE9629';
    // } else {
    //     Vue.prototype.$vuetify.theme.primary = '#00A3E0';
    //     Vue.prototype.$vuetify.theme.accent = '#DE7C00';
    // }
};

const configActions: ActionTree<BrowserConfigState, any> = {
    getDark: ({commit}): any => {
        const value: string|null = localStorage.getItem('config.dark');
        if (value != null) {
            const myVal = JSON.parse(value);
            commit('setConfig', {key: 'dark', value: myVal});
            setThemeColors(myVal);
        } else {
            // Default is dark mode
            commit('setConfig', {key: 'dark', value: true});
            setThemeColors(true);
        }
    },
    setDark: ({commit}, value): any => {
        commit('setConfig', {key: 'dark', value});

        if (value) {
            commit('setConfig', {key: 'logo', value: 'dark-logo'});
        } else {
            commit('setConfig', {key: 'logo', value: 'white-logo'});
        }

        localStorage.setItem('config.dark', JSON.stringify(value));
        setThemeColors(value);
    },
};

const BrowserConfigModule = {
    namespaced: true,
    state: {
        config: {
            dark: false,
            logo: 'dark-logo',
        },
    },
    mutations: {
        setConfig: (state: BrowserConfigState, {key, value}: {key: string; value: string}) => {
            state.config[key] = value;
        },
        clearConfig: (state: BrowserConfigState, key: string) => {
            delete state.config[key];
        },
    },
    getters: {
        dark: (state: BrowserConfigState) => state.config.dark,
        getLogo: (state: BrowserConfigState) => state.config.logo,
    },
    actions: configActions,
};

export {BrowserConfigModule};
