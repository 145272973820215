import {ActionTree, Commit} from 'vuex';
import {AuthState} from '@/types';
import LoginAPI from '@/services/api/Login';
import RegisterAPI from '@/services/api/Register';
import ResetPasswordAPI from '@/services/api/ResetPassword';
import {AxiosResponse} from 'axios';

const setLogginedState = (commit: Commit, logginedState: boolean) => {
    commit('setLogginedState', logginedState);
};

const authActions: ActionTree<AuthState, any> = {
    login: ({commit}, {user, pass}): PromiseLike<any> => {
        const p = LoginAPI.login({username: user, password: pass});

        p.then(response => {
            setLogginedState(commit, true);
        });

        return p;
    },
    registration: (context, {
        firstName, lastName, email, password,
        phone, timeZone, companyName, hourFormat, subscribed,
    }): PromiseLike<any> => RegisterAPI.registration(
        {firstName, lastName, email, password, phone, timeZone, companyName, hourFormat, subscribed},
    ),
    sendResetLink: (context, {email}): PromiseLike<any> =>
        ResetPasswordAPI.sendResetLink({email}),

    logout: ({commit}): Promise<AxiosResponse> => {
        setLogginedState(commit, false);
        commit('setCompliance', false);

        return LoginAPI.logout();
    },

    setCompliance: ({ commit }) => {
        commit('setCompliance');
    },
};

const AuthModule = {
    namespaced: true,
    state: {
        isLoggedIn: false,
        isComplianceShown: false,
    },
    mutations: {
        setLogginedState: (state: AuthState, isLoggedIn: boolean) => {
            state.isLoggedIn = isLoggedIn;
        },
        setCompliance: (state: AuthState, complianceStatus: boolean = true) => {
            state.isComplianceShown = complianceStatus;
        },
    },
    getters: {
        isUserLoggedIn: async (state: AuthState) => {
            if (! state.isLoggedIn) {
                const res = await LoginAPI.authorized();
                state.isLoggedIn = res.data.success;
            }

            return state.isLoggedIn;
        },
        isComplianceShown: (state: AuthState) => state.isComplianceShown,
    },
    actions: authActions,
};

export {AuthModule};
