import Vue from 'vue';
import Router from 'vue-router';
import {Route} from 'vue-router';
import store from './store';

Vue.use(Router);

let entryUrl: string | null = null;

const guard = async (to: Route, from: Route, next: (url?: string) => void) => {

    if (await store.getters['authState/isUserLoggedIn']) {
        if (entryUrl) {
            const url = entryUrl;
            entryUrl = null;
            return next(url);
        } else {
            return next();
        }
    } else {
        entryUrl = to.path;
        next('/login');
    }
};

const logoutGuard = async (to: Route, from: Route, next: (url?: string) => void) => {
    store.dispatch('authState/logout').then(() => {
        next('/login');
    });
};

const loggedInGuard = async (to: Route, from: Route, next: (url?: string) => void) => {
    if (await store.getters['authState/isUserLoggedIn']) {
        next('home');
    } else {
        next();
    }

};

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'entry',
            component: () => import(/* WebpackChunkName: "entry" */ './views/Entry.vue'),
            redirect: () => {
                if (store.getters['authState/isUserLoggedIn']) {
                    // If we're logged in, show this page
                    return 'licensing-report';
                } else {
                    return 'login';
                }
            },
            children: [
                {
                    path: '/login',
                    name: 'login',
                    component: () => import(/* WebpackChunkName: "login" */ './components/Login.vue'),
                    beforeEnter: loggedInGuard,
                },
                {
                    path: '/register',
                    name: 'register',
                    component: () => import(/* WebpackChunkName: "register" */ './components/Register.vue'),
                    beforeEnter: loggedInGuard,
                },

            ],
        },
        {
            path: '/reset-password',
            name: 'resetPassword',
            component: () => import( './views/ResetPassword.vue'),
        },
        {
            path: '/account',
            name: 'changePassword',
            component: () => import( './views/ChangePassword.vue'),
            beforeEnter: guard,
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('./views/Home.vue'),
            beforeEnter: guard,
        },
        {
            path: '/connect',
            name: 'connect',
            component: () => import('./views/Connect.vue'),
            beforeEnter: guard,
        },
        {
            path: '/appliance',
            name: 'appliance',
            component: () => import('./views/Appliance.vue'),
            beforeEnter: guard,
        },
        {
            path: '/stornext-software',
            name: 'stornext-software',
            component: () => import('./views/StornextSoftware.vue'),
            beforeEnter: guard,
        },
        {
            path: '/licensing-report',
            name: 'licensing-report',
            component: () => import('./views/LicensingReport.vue'),
            beforeEnter: guard,
        },
        {
            path: '/profile',
            name: 'profile',
            component: () => import('./views/Profile.vue'),
            beforeEnter: guard,
        },
        {
            path: '*',
            component: () => import('./views/Home.vue'),
        },
        {
            path: '/logout',
            name: 'logout',
            beforeEnter: logoutGuard,
        },
        {
            path: '/validate-email',
            name: 'validate-email',
            component: () => import('./views/ValidateEmail.vue'),
            props: true,
        },
        {
            path: '/users',
            name: 'users',
            component: () => import('./components/UsersManagement/UsersList.vue'),
            beforeEnter: guard,
        },
        {
            path: '/delegate',
            name: 'delegate',
            component: () => import('./components/UsersManagement/DelegateList.vue'),
            beforeEnter: guard,
        },
        {
            path: '/usui-downloads',
            name: 'usui-downloads',
            component: () => import('./views/Downloads.vue'),
            beforeEnter: guard,
        },
        {
            path: '/download-metrics',
            name: 'download-metrics',
            component: () => import('./components/DownloadMetrics/DownloadMetricList.vue'),
            beforeEnter: guard,
        },
    ],
});


export default router;
