/*
 * Copyright 2019. Quantum Corporation. All Rights Reserved.
 * StorNext is either a trademark or registered trademark of
 * Quantum Corporation in the US and/or other countries.
 *
 */

import {ActionTree} from 'vuex';
import {SiteConfigState, SiteConfig} from '@/types';
import axios, {AxiosError, AxiosResponse} from 'axios';

const configActions: ActionTree<SiteConfigState, any> = {
    getConfig: ({commit}): Promise<any> => new Promise((resolve, reject) => {
        axios.get('/config/site-config.json')
            .then((response: AxiosResponse<any>) => {
                const value: any = response.data;

                if (value != null) {
                    if (typeof(value) === 'string') {
                        throw new Error('Site config is not valid JSON!');
                    }
                    if (value.hasOwnProperty('backend')) {
                        commit('setConfig', value);
                    } else {
                        throw new Error('Site config is missing backend information!');
                    }
                } else {
                    throw new Error('Site configuration is missing!');
                }
                resolve(response);
            })
            .catch((error: AxiosError) => {
                reject(error);
            });
    }),
};

const SiteConfigModule = {
    namespaced: true,
    state: {
        config: null as SiteConfig | null,
    },
    mutations: {
        setConfig: (state: SiteConfigState, val: SiteConfig) => {
            state.config = val;
        },
    },
    getters: {
        getBackend: (state: SiteConfigState) => {
            if (state.config && state.config.backend) {
                return state.config.backend;
            } else {
                return null;
            }
        },

        isProductKeyFeatureEnabled: (state: SiteConfigState) => {
            if (state.config && 'productKeyFeature' in state.config) {
                return state.config.productKeyFeature;
            }

            return null;
        },
    },
    actions: configActions,
};

export {SiteConfigModule};
