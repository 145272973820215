import axios from 'axios';
import commonAxios from '@/services/api/Common';
import store from '@/store';

export default {
    sendResetLink: (parameters: { email: string }) => {
        const api = axios.create({
            withCredentials: true,
        });
        return api.post(store.getters['siteConfigState/getBackend'] + '/api/app-store/v1/send-resetting-link',
                        parameters,
                        {
                            headers:
                    {'Content-Type': 'application/json'},
                        });
    },
    resetPassword: (parameters: { password: string; token: string }) => {
        const api = axios.create({
            withCredentials: true,
        });
        return api.post(store.getters['siteConfigState/getBackend'] + '/api/app-store/v1/reset-password',
                        parameters,
                        {
                            headers:
                    {'Content-Type': 'application/json'},
                        });
    },

    changePassword: (parameters: { old_password: string; new_password: string }) => {
        const api = commonAxios.create({
            withCredentials: true,
        });
        return api.post(store.getters['siteConfigState/getBackend'] + '/api/v2/user/change-password',
                        parameters,
                        {
                            headers:
                    {'Content-Type': 'application/json'},
                        });
    },
};
