













import Vue from 'vue';
import axios from 'axios';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class AgreementDialogReader extends Vue {
    @Prop({ required: true, type: String })
    public document!: string;
    public dialog: boolean = false;
    public agreementText: string = '';

    public created(): void {
        axios.get(`/privacy/${this.document}`).then(response => {
            this.agreementText = response.data;
        }).catch(error => {
            console.error(error);
        });
    }
}
