

























































import Vue from 'vue';
import { mapGetters } from 'vuex';
import Vuetify from 'vuetify/lib';
import Entry from './views/Entry.vue';
import MainMenu from './views/MainMenu.vue';
import AppHeader from './components/AppHeader.vue';
import { Component } from 'vue-property-decorator';
import { LEFT_MENU, POP_MENU } from '@/models/MenuModel';
import ProgressSpinner from './components/Widgets/ProgressSpinner.vue';
import NotificationWidget from './components/Widgets/NotificationWidget.vue';
import ComplianceStatusDialog from '@/components/ComplianceStatusDialog.vue';
import AgreementDialogReader from '@/components/common/AgreementDialogReader.vue';
import QuantumFooter from '@quantum/common-vue-components/src/components/Footer/Footer.vue';
import QuantumAppBar from '@quantum/common-vue-components/src/components/AppBar/AppBar.vue';
import QuantumMenu from '@quantum/common-vue-components/src/components/LeftMenu/LeftMenu.vue';

Vue.use(Vuetify);

@Component({
    components: {
        QuantumFooter,
        QuantumAppBar,
        QuantumMenu,
        appMenu: MainMenu,
        appHeader: AppHeader,
        progressSpinner: ProgressSpinner,
        notification: NotificationWidget,
        entry: Entry,
        AgreementDialogReader,
        ComplianceStatusDialog,
    },
    computed: {
        ...mapGetters('browserConfigState', {isDark: 'dark'}),
        ...mapGetters('notificationState', {
            getSpinnerMessage: 'spinnerMessage',
            getSpinnerOverlay: 'overlay',
        }),
    },
}) export default class App extends Vue {
    public isDark!: boolean;
    public menuShow: boolean = false;
    public logged: boolean = false;
    public appVersionString: string = process.env.VUE_APP_VERSION;
    public minify: boolean = false;
    public menuItems: any[] = LEFT_MENU;
    public popMenu: any[] = POP_MENU;
    public helpUrl: string = 'https://www.quantum.com/documentation';
    public userName: string = '';

    public mounted(): void {
        this.$store.dispatch('browserConfigState/getDark');

        const darkTheme = localStorage.getItem('darktheme');

        this.setTheme(darkTheme);
    }

    public async beforeCreate() {
        const isLogged: boolean = await this.$store.getters['authState/isUserLoggedIn'];

        if (isLogged) {
            await this.$store.dispatch('profile/getProfile');
        }
    }

    public created(): void {
        this.$store.watch(state => state.menuMinimized, () => {
            this.minify = this.$store.state.menuMinimized;
        });

        this.$store.watch(state => state.profile.profile, () => {
            this.setUserName();

            this.setMenuRestrictions();
        });
    }

    public setMenuRestrictions(): void {
        this.menuItems.forEach(menu => {
            if (menu.roles) {
                const roles: string[] = this.$store.getters['profile/getRoles'];

                const isVisible: boolean = menu.roles.some((role: string) => roles.includes(role));

                Object.assign(menu, { isVisible });
            }
        });
    }

    public get branding() {
        let str = '<span style="padding-right:4px;font-weight:normal;';
        str += 'text-transform:none;white-space: nowrap;';
        str += 'font-family:helvetica;color:#00b6f1">' + 'myStorNext' + ' </span>';

        return {
            logoSubHeader: str,
        };
    };

    public setUserName(): void {
        const profileState = this.$store.state.profile;

        this.userName = [profileState.profile.first_name, profileState.profile.last_name].join(' ');
    }

    public updated() {
        this.isUserLoggedIn();
    }

    public setTheme(val: any): void {
        const darkTheme = localStorage.getItem('darktheme');
        const isDarkTheme = JSON.parse((darkTheme == null) ? true : val);

        this.$vuetify.theme.dark = isDarkTheme;
        localStorage.setItem('darktheme', isDarkTheme);
        this.$store.dispatch('setTheme', isDarkTheme);
    }

    public async isUserLoggedIn() {
        this.logged = await this.$store.getters['authState/isUserLoggedIn'];
    }

    public toggleMenu(): void {
        this.menuShow = !this.menuShow;
    }

    private minifyFxn(): void {
        this.$store.commit('toggleMenuMinimized');
    }
}
