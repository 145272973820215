import 'babel-polyfill';
import Vue from 'vue';
import Vuetify from 'vuetify';
import vuetify from '@/plugins/vuetify';
import App from './App.vue';
import 'scrolling-element';
import router from './router';
import {store} from './store';
import './assets/css/main.css';
import '@mdi/font/css/materialdesignicons.css';
import 'vue-material-design-icons/styles.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';


Vue.config.productionTip = false;
store.dispatch('siteConfigState/getConfig')
    .then(() => {
        new Vue({
            router,
            store,
            vuetify,
            render: h => h(App),
        }).$mount('#app');
    });
