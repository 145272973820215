import Vue from 'vue';
import Vuex from 'vuex';
import {AuthModule} from './auth';
import ProfileModule from '@/store/profile';
import LicenseModule from '@/store/license';
import {BrowserConfigModule} from './browserConfig';
import {SiteConfigModule} from '@/store/siteConfig';
import {NotificationModule} from '@/store/notification';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        authState: AuthModule,
        browserConfigState: BrowserConfigModule,
        siteConfigState: SiteConfigModule,
        notificationState: NotificationModule,
        profile: ProfileModule,
        licenseModule: LicenseModule,
    },
    state: {
        menuMinimized: false as boolean,
        darktheme: true as boolean,
        tooltips: true,
    },
    mutations: {
        toggleMenuMinimized: (state: any) => {
            state.menuMinimized = !state.menuMinimized;
        },
        setTheme: (state: any, d: any) => {
            if (d) {
                state.inputHL = 'white';
            } else {
                state.inputHL = 'primary';
            }

            state.darktheme = d;
        },
    },
    getters: {
        menuMinimized: (state: any) => state.menuMinimized,
    },
    actions: {
        setTheme: ({ commit }: { commit: (commit: string, d: any) => void }, d: any) => {
            commit('setTheme', d);
        },
    },
});

export default store;
