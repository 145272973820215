import { UNITS_SIZE } from '@/models/UnitsModel';


export const bytesToUnit = (bytes: number) => {
    if (bytes === 0) {
        return UNITS_SIZE[0];
    }
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return UNITS_SIZE[i];
};

export const bytesToUnitValue = (bytes: number) => {
    if (bytes === 0) {
        return 0;
    }
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(2));
};
