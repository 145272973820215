/*
 * Copyright 2019. Quantum Corporation. All Rights Reserved.
 * StorNext is either a trademark or registered trademark of
 * Quantum Corporation in the US and/or other countries.
 *
 */

import {NotificationState} from '@/types';

const NotificationModule = {
    namespaced: true,
    state: {
        spinnerMessage: undefined as string | undefined,
        errorMessage: undefined as string | undefined,
        successMessage: undefined as string | undefined,
        hideOverlay: true,
    },
    mutations: {
        setSpinnerMessage: (state: NotificationState, msg: string) => {
            state.spinnerMessage = msg;
        },
        clearSpinnerMessage: (state: NotificationState) => {
            state.spinnerMessage = undefined;
        },
        setErrorMessage: (state: NotificationState, msg: string) => {
            state.errorMessage = msg;
        },
        clearErrorMessage: (state: NotificationState) => {
            state.errorMessage = undefined;
        },
        setSuccessMessage: (state: NotificationState, msg: string) => {
            state.successMessage = msg;
        },
        clearSuccessMessage: (state: NotificationState) => {
            state.successMessage = undefined;
        },
        setOverlay: (state: NotificationState, hideOverlay: boolean = true) => {
            state.hideOverlay = hideOverlay;
        },
    },
    getters: {
        spinnerMessage: (state: NotificationState) => state.spinnerMessage,
        errorMessage: (state: NotificationState) => state.errorMessage,
        successMessage: (state: NotificationState) => state.successMessage,
        overlay: (state: NotificationState) => state.hideOverlay,
    },
    // Actions: notificationActions,
};

export {NotificationModule};
