import store from '@/store';
import { AxiosPromise } from 'axios';
import axios from '@/services/api/Common';

export default {
    getLicensingReports: () => {
        const api = axios.create({
            withCredentials: true,
        });

        return api.get(`${store.getters['siteConfigState/getBackend']}/api/v2/reports`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },

    getLicensingReportBySystemID: (systemID: string) => {
        const api = axios.create({
            withCredentials: true,
        });

        return api.get(`${store.getters['siteConfigState/getBackend']}/api/v2/system/${systemID}/reports`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },

    updateSync: (systemID: string) => {
        const api = axios.create({
            withCredentials: true,
        });

        return api.get(`${store.getters['siteConfigState/getBackend']}/api/v2/system/${systemID}/update-features`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },

    getLicenseSummary: () => {
        const api = axios.create({
            withCredentials: true,
        });

        return api.get(`${store.getters['siteConfigState/getBackend']}/api/v2/license/summary`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },

    uploadLicenseReport: (licenseJson: string): AxiosPromise => {
        const api = axios.create({
            withCredentials: true,
        });

        return  api.post(store.getters['siteConfigState/getBackend'] + '/api/v2/web/reports', licenseJson);

    },

    getRecentReportJson: (systemUuid: string): AxiosPromise => {
        const api = axios.create({
            withCredentials: true,
        });

        return api.get(`${store.getters['siteConfigState/getBackend']}/api/v2/systems/${systemUuid}/report/src`);
    },

    downloadRecentReportJson: (systemUuid: string): AxiosPromise => {
        const api = axios.create({
            withCredentials: true,
            responseType: 'blob',
        });

        return api.get(
            `${store.getters['siteConfigState/getBackend']}/api/v2/systems/${systemUuid}/report/src/download`,
        );
    },

    getApproachingCoefficients: (): AxiosPromise => {
        const api = axios.create({
            withCredentials: true,
        });

        return api.get(`${store.getters['siteConfigState/getBackend']}/api/v2/license/coefficients`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },
};
