import store from '@/store';
import { ProfileInterface } from '@/interfaces/ProfileStateInterface';
import { License, LicenseSystemInterface } from '@/interfaces/LicenseInterface';
import { APPROACHING_TAPE_AND_CLOUD, APPROACHING_FS, COMPLIANCE_ALERT, IS_PRODUCT_KEY } from '@/constants/License';

export default class LicensingComplianceService {
    public static isApproachingCapacity(license: License, approachingReports: string[]): void {
        let coeff: number = 1;
        const usage: number = Number(license.usage_bytes);
        const authorized: number = Number(license.authorized_bytes);
        const configured: number = Number(license.configured_bytes);

        switch (license.original_name) {
            case 'fs':
                coeff = store.getters['licenseModule/getConfiguredCoeff'];
                break;
            case 'tape':
                coeff = store.getters['licenseModule/getAuthorizedCoeff'];
                break;
            case 'totalCloud':
                coeff = store.getters['licenseModule/getAuthorizedCoeff'];
                break;
        }

        // Tape and cloud
        if (
            ! approachingReports.includes('tape')
            && ['tape', 'totalCloud'].includes(license.original_name) && (configured > (authorized * (coeff / 100)))
        ) {
            approachingReports.push('tape');
        }

        // Fs
        if (
            ! approachingReports.includes('fs')
            && license.original_name === 'fs' && (usage > (configured * (coeff / 100)))
        ) {
            approachingReports.push('fs');
        }
    }

    public static getApproachingCapacityNotification(licenses: License[]): string {
        let capacityNotification: string = '';
        const approachingReports: string[] = [];
        const reportMessage: any = {
            fs: APPROACHING_FS,
            tape: APPROACHING_TAPE_AND_CLOUD,
        };

        for (const license of licenses) {
            if (license.type === 'Count') {
                continue;
            }

            this.isApproachingCapacity(license, approachingReports);
        }

        if (approachingReports.length) {
            approachingReports.forEach((report: string) => {
                if (reportMessage.hasOwnProperty(report)) {
                    capacityNotification += reportMessage[report];
                }
            });
        }

        return capacityNotification;
    }

    public static getCompliancePriority(profile: ProfileInterface, reports: LicenseSystemInterface[]) {
        const compliancePriority: any = {
            no_hash_key: null,
            out_compliance: null,
            approaching_capacity: null,
        };

        for (const report of reports) {
            if (! report.product_hash_key && report.is_alert) {
                compliancePriority.no_hash_key = COMPLIANCE_ALERT;
            }

            if (report.product_hash_key && report.is_alert) {
                compliancePriority.out_compliance = IS_PRODUCT_KEY;
            }

            if (profile.subscribed) {
                if (! compliancePriority.approaching_capacity) {
                    compliancePriority.approaching_capacity = this.getApproachingCapacityNotification(
                        report.licenses,
                    );
                }
            }
        }

        return compliancePriority;
    }
}
