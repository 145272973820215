



















import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component
class NotificationWidget extends Vue {

    public message: string = '';
    public show: boolean = false;
    public type: string = '';

    public created(): void {
        this.$store.watch(state => state.notificationState.successMessage, () => {
            const msg = this.$store.getters['notificationState/successMessage'];
            if (msg) {

                this.message = msg;
                this.type = 'success';
                this.show = true;
                this.$store.commit('notificationState/clearSuccessMessage');
            }
        });
        this.$store.watch(state => state.notificationState.errorMessage, () => {
            const msg = this.$store.getters['notificationState/errorMessage'];
            if (msg) {
                this.message = msg;
                this.type = 'error';
                this.show = true;

                this.$store.commit('notificationState/clearErrorMessage');
            }
        });
    }

}

export default NotificationWidget;

