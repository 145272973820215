






























































































































import Vue from 'vue';
import moment from 'moment-timezone';
import ProfileApi from '@/services/api/Profile';
import { Component } from 'vue-property-decorator';
import UsersService from '@/services/users/UsersSortService';
import { ProfileInterface } from '@/interfaces/ProfileStateInterface';

@Component
export default class Profile extends Vue {
    public valid: boolean = true;
    public timeZone = '';
    public successMessage: string;
    public serverErrorMessage: string;
    public dark: boolean;
    public hourFormats: number[] = [12, 24];

    public rules = {
        phone: (value: string) => {
            if (value) {
                const pattern = new RegExp(/^[+]?(?!-)([(][0-9]{1,3}[)])?([-\s]?[0-9])+$/);
                return pattern.test(value) || 'Invalid phone number';
            } else {
                return true;
            }
        },
        phoneMaxLength: (value: string) => {
            if (value) {
                return value.trim().length < 21 || 'Phone number should not be longer than 20 characters';
            } else {
                return true;
            }
        },
    };

    public get roles(): string {
        if (this.profile.roles) {
            return UsersService.getHumanReadableRoles(this.profile.roles);
        }

        return '';
    }

    public constructor() {
        super();
        this.successMessage = 'Profile has been successfully updated ';
        this.serverErrorMessage = 'Server error';
        this.dark = this.$store.getters['browserConfigState/dark'];
    }

    public changePassword(): void {
        this.$router.push('/account');
    }

    public get profile(): ProfileInterface {
        return this.$store.getters['profile/getProfile'];
    }

    public get timeZones(): string[] {
        const timeZones: string[] = moment.tz.names();
        const offsetTmz: string[] = [];

        timeZones.forEach((timeZone: string) => {
            const offset: string = `(GMT${moment.tz(timeZone).format('Z')}) ${timeZone}`;

            if (this.profile.time_zone === timeZone) {
                this.profile.time_zone = offset;
            }

            offsetTmz.push(offset);
        });

        return offsetTmz;
    }

    public goBack() {
        this.$router.go(-1);
    }

    public mounted() {
        this.getProfile();
    }

    public toggleDark() {
        this.$store.dispatch('browserConfigState/setDark', this.dark);
    }

    public getProfile() {
        this.$store.dispatch('profile/getProfile');
    }

    public getHumanReadableRoles(): string {
        return UsersService.getHumanReadableRoles(this.profile.roles);
    }

    public submit(
        phoneNumber: string, timeZone: string, subscribed: boolean, companyName: string, hourFormat: number,
    ): void {
        if ((this.$refs.form as any).validate()) {
            this.$store.commit('notificationState/setSpinnerMessage', 'Updating profile...');

            const tz: any = timeZone ? timeZone.split(' ').pop() : '';

            ProfileApi.updateProfile(
                {
                    phone_number: phoneNumber, time_zone: tz, subscribed,
                    company_name: companyName, hour_format: hourFormat ,
                },
            ).then(response => {
                this.$store.commit('notificationState/clearSpinnerMessage');

                if (response.data.success) {
                    this.$store.commit('notificationState/setSuccessMessage', this.successMessage);
                } else {
                    this.$store.commit('notificationState/setErrorMessage', response.data.error);
                }

                this.goBack();
            }).catch(() => {
                this.$store.commit('notificationState/clearSpinnerMessage');
                this.$store.commit('notificationState/setErrorMessage', this.serverErrorMessage);
            });
        }
    }
}
