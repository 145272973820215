import axios, {AxiosPromise} from 'axios';
import store from '@/store';
import 'url-search-params-polyfill';
export default {
    self: this,
    login: (parameters: { username: string; password: string }) => {
        const api = axios.create({
            withCredentials: true,
        });
        return api.post(store.getters['siteConfigState/getBackend'] + '/login_check',
                        parameters,
                        {
                            headers:
                    {'Content-Type': 'application/json'},
                        },
        );
    },
    logout: () => {
        const api = axios.create({
            withCredentials: true,
        });
        return api.post(store.getters['siteConfigState/getBackend'] + '/logout',
                        {
                            headers:
                    {'Content-Type': 'application/json'},
                        },
        );
    },
    authorized: (): Promise<AxiosPromise<any>> => {
        const api = axios.create({
            withCredentials: true,
        });
        return api.get(store.getters['siteConfigState/getBackend'] + '/api/v2/user/authorized',
                       {
                           headers:
                    {'Content-Type': 'application/json'},
                       },
        );
    },

};
