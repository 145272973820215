import store from '@/store/index';
import ProfileApi from '@/services/api/Profile';
import { ProfileStateInterface, ProfileInterface } from '@/interfaces/ProfileStateInterface';

const ProfileModule = {
    namespaced: true,
    state: {
        profile: {
            roles: [],
        },
    },
    mutations: {
        setProfile: (state: ProfileStateInterface, profile: ProfileInterface): void => {
            state.profile = profile;
        },
    },
    getters: {
        getProfile: (state: ProfileStateInterface): ProfileInterface => state.profile,

        isAdmin: (state: ProfileStateInterface): boolean => state.profile.roles.includes('ROLE_ADMIN'),

        getRoles: (state: ProfileStateInterface): string[] => state.profile.roles,

        isLicenseAdmin: (state: ProfileStateInterface): boolean => state.profile.roles.includes('ROLE_LICENSE_ADMIN'),
    },
    actions: {
        getProfile: ({ commit }: { commit: (commit: string, profile: ProfileInterface) => void }): void => {
            ProfileApi.getProfile()
                .then(response => {
                    commit('setProfile', response.data.user);
                })
                .catch(() => {
                    store.commit('notificationState/setErrorMessage', 'Server Error');
                });
        },
    },
};

export default ProfileModule;
