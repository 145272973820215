import store from '@/store';
import axios from '@/services/api/Common';

export default {
    getProfile: () => {
        const api = axios.create({
            withCredentials: true,
        });
        return api.get(store.getters['siteConfigState/getBackend'] + '/api/v2/user/profile',
                       {
                           headers:
                    {'Content-Type': 'application/json'},
                       });
    },

    updateProfile: (parameters: {
        phone_number: string; time_zone: string; subscribed: boolean; company_name: string; hour_format: number;
    }) => {
        const api = axios.create({
            withCredentials: true,
        });
        return api.post(store.getters['siteConfigState/getBackend'] + '/api/v2/user/profile',
                        parameters,
                        {
                            headers:
                    {'Content-Type': 'application/json'},
                        });
    },
};
