import store from '@/store';
import DateUtil from '@/utils/DateUtil';
import { ProfileInterface } from '@/interfaces/ProfileStateInterface';
import { bytesToUnit, bytesToUnitValue } from '@/utils/UnitsFormatter';
import {
    License,
    LicenseSummaryInterface,
    LicenseSystemInterface,
    GatewayLicenseSummaryInterface,
} from '@/interfaces/LicenseInterface';
import ReportRowColorService from '@/services/licensing/ReportRowColorService';

export default class LicenseTransformer {
    public static getTransformer(reports: any[], isSummary: boolean): LicenseSystemInterface[] {
        const profile: ProfileInterface = store.getters['profile/getProfile'];
        const hourFormat: string = profile.hour_format === 24 ? 'HH:mm:ss' : 'hh:mm:ss A';
        let tz: string = '';

        if (profile.time_zone) {
            tz = profile.time_zone;
        }

        const dateFormat: string = `ddd MMM DD ${hourFormat} z YYYY`;

        return reports.map((report: any) => {
            let   licenses: License[] = LicenseTransformer.getLicensesTransformer(report.licenses);
            const buildDate: string = report.buildDate ? DateUtil.dateToFormat(report.buildDate, dateFormat, tz) : '';
            const lastReport: number = report.licenseLastUpdateTime * 1000;
            const lastUpdate: string = report.featuresLastUpdateTime ? DateUtil.millisecondsToDateFormat(
                report.featuresLastUpdateTime * 1000, dateFormat, tz,
            ) : 'N/A';
            const lastUpload: string = report.licenseLastUploadTime ? DateUtil.millisecondsToDateFormat(
                report.licenseLastUploadTime * 1000, dateFormat, tz,
            ) : 'N/A';
            const canUpdate: boolean = Boolean(DateUtil.diffInHours(
                (new Date()).getTime(), report.featuresLastUpdateTime * 1000,
            ));

            if (isSummary) {
                licenses = licenses.filter((license: License) => {
                    if (license.type === 'Capacity') {
                        return ['fs', 'tape', 'totalCloud'].includes(license.original_name);
                    }

                    return license.original_name === 'totalNodes';
                });
            }

            return {
                cba_id: report.systemExternalCbaId,
                mdc_state: report.MDCHaState,
                system_id: report.systemId,
                build_date: buildDate,
                expired_date: LicenseTransformer.getLicenseExpiresDate(licenses, 'YYYY/MM/DD', tz),
                last_report: DateUtil.millisecondsToDateFormat(lastReport, dateFormat, tz),
                last_upload: lastUpload,
                system_uuid: report.systemUuid,
                system_version: report.systemVersion,
                registration_id: report.licenseSystemSerialNumber,
                local_os_version: report.localOSVersion,
                product_hash_key: report.productKeyHash,
                total_license_nodes: report.totalLicenseNodes,
                license_system_serial_number: report.licenseSystemSerialNumber,
                license_last_quantum_license_info_sync_time: lastUpdate,
                licenses,
                is_alert: licenses.some((license: License) => {
                    if (LicenseTransformer.isSummaryLicense(license.original_name, license.type)) {
                        return license.row_color === 'red';
                    }
                }),
                unix_last_upload: report.licenseLastUploadTime,
                unix_last_report_time: lastReport,
                unix_quantum_update_time: report.featuresLastUpdateTime * 1000,
                can_update: canUpdate,
                owner: report.owner,
                is_system_remove_allowed: report.isAllowedToRemoveSystem,
            };
        });
    }

    public static isSummaryLicense(licenseName: string, type: string): boolean {
        const licenses: { name: string; type: string }[] = [
            {
                name: 'fs',
                type: 'Capacity',
            },
            {
                name: 'totalNodes',
                type: 'Count',
            },
            {
                name: 'tape',
                type: 'Capacity',
            },
            {
                name: 'totalCloud',
                type: 'Capacity',
            },
        ];

        const found = licenses.find(license => license.name === licenseName);

        if (found) {
            return found.type === type;
        }

        return false;
    }

    public static getLicenseSummaryTransformed(
        licenseSummary: LicenseSummaryInterface[],
    ): GatewayLicenseSummaryInterface {
        const gateways = new Set();

        licenseSummary.forEach((item: LicenseSummaryInterface, index) => {
            gateways.add(licenseSummary[index].gateway);
        });

        const summary: GatewayLicenseSummaryInterface = {};

        for (const gateway of gateways) {
            summary[`${gateway}`] = licenseSummary.filter((item: LicenseSummaryInterface) => item.gateway === gateway);
        }

        return summary;
    }

    private static getLicenseExpiresDate(licenses: any[], dateFormat: string, tz: string): string {
        const expiresLicense: any = licenses.find((license: any) => license.expired_date);

        return expiresLicense
            ? DateUtil.millisecondsToDateFormat(expiresLicense.expired_date * 1000, dateFormat, tz)
            : 'N/A';
    }

    private static getLicensesTransformer(licenses: any[]): License[] {
        return licenses.map((license: Record<string, any>) => {
            const usage: { usage: number; unit: string } = LicenseTransformer.convertUsage(
                license.type, Number(license.usage),
            );

            const authorizedUsage: { usage: number; unit: string } = LicenseTransformer.convertUsage(
                license.type, license.authorizedUsage,
            );

            const configuredUsage: { usage: number; unit: string } = LicenseTransformer.convertUsage(
                license.type, license.configured,
            );

            let rowColor: string = '';

            if (LicenseTransformer.isSummaryLicense(license.name, license.type)) {
                rowColor = ReportRowColorService.getRowColor(license);
            }

            return {
                name: LicenseTransformer.licenseHumanReadableName(license.name),
                type: license.type,
                usage: usage.usage,
                status: license.inUse,
                row_color: rowColor,
                usage_unit: usage.unit,
                original_name: license.name,
                usage_bytes: Number(license.usage),
                expired_date: license.expiredDate,
                configured_usage: configuredUsage.usage,
                configured_bytes: Number(license.configured),
                configured_usage_unit: configuredUsage.unit,
                authorized: license.authorized,
                authorized_usage: authorizedUsage.usage,
                authorized_bytes: Number(license.authorizedUsage),
                authorized_usage_unit: authorizedUsage.unit,
            };
        });
    }

    private static licenseHumanReadableName(licenseName: string): string {
        switch (licenseName) {
            case 'fs':
                return 'StorNext 7 File System Capacity License';
            case 'totalNodes':
                return 'StorNext 7 Scale-Out Data Services Node License';
            case 'totalTiers':
                return 'Total Tiers';
            case 'tape':
                return 'StorNext 7 Tape Capacity License';
            case 'totalCloud':
                return 'StorNext 7 Public and Private Cloud Capacity License';
            default:
                return licenseName;
        }
    }

    private static convertUsage(type: string, licenseUsage: number): { usage: number; unit: string } {
        const converted: { usage: number; unit: string } = {
            usage: 0,
            unit: '',
        };

        if (type === 'Capacity') {
            converted.usage = bytesToUnitValue(licenseUsage);
            converted.unit  = bytesToUnit(licenseUsage);

            return converted;
        }

        converted.usage = licenseUsage;

        return converted;
    }
}
