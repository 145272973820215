












import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ProfileInterface } from '@/interfaces/ProfileStateInterface';
import { LicenseSystemInterface } from '@/interfaces/LicenseInterface';
import LicensingComplianceService from '@/services/licensing/LicensingComplianceService';

@Component
export default class ComplianceStatusDialog extends Vue {
    public dialog: boolean = false;
    public licenses: LicenseSystemInterface[] = [];
    public complianceMsg: string = '';

    public mounted(): void {
        this.$store.watch(state => state.licenseModule.licenses, (reports: LicenseSystemInterface[]) => {
            if (! this.isComplianceShown) {
                this.setCompliance(reports);
            }
        });
    }

    public get isComplianceShown(): boolean {
        return this.$store.getters['authState/isComplianceShown'];
    }

    public setCompliance(reports: LicenseSystemInterface[]): void {
        const profile: ProfileInterface = this.$store.getters['profile/getProfile'];
        const compliancePriority = LicensingComplianceService.getCompliancePriority(profile, reports);

        for (const compliance in compliancePriority) {
            if (compliancePriority.hasOwnProperty(compliance) && compliancePriority[compliance]) {
                this.showComplianceDialog(compliancePriority[compliance]);

                break;
            }
        }
    }

    public showComplianceDialog(msg: string): void {
        this.complianceMsg = msg;
        this.dialog = true;

        this.$store.dispatch('authState/setCompliance');
    }
}
