<!--
  -
  - Copyright 2019. Quantum Corporation. All Rights Reserved.
  - StorNext is either a trademark or registered trademark of
  - Quantum Corporation in the US and/or other countries.
  -
  -->

<template lang='pug'>
    v-main
        v-container.pt-5(fluid='')
            v-layout(justify-center='', align-center='')
                v-flex.pt-12(lg10)
                    v-card.mt-5
                        v-layout(fill-height='')
                            v-flex.hidden-md-and-down(lg6 xs6 md12)
                                v-container(fluid fill-height)
                                    v-layout(flex align-center justify-center)
                                        span.logo-text Quantum myStorNext
                                        img.logo(:src='logo', alt='StorNext', height='80px')
                            v-flex(xs12, sm6, md12, lg6)
                                v-card.elevation-0(height='100%')
                                    v-card-title
                                        v-container
                                            v-layout.hidden-lg-and-up(row='')
                                                v-img(height='35px', contain='', :src='logo')
                                            v-layout(align-center justify-center row)
                                                div
                                                    v-row(justify="space-around")
                                                        v-tabs(:v-model='active_tab')
                                                            v-tab#registerTab(to='register')
                                                                | Register
                                                            v-tab#loginTab(to='login')
                                                                | Login
                                    v-card-text
                                        router-view
</template>

<script>
export default {
    data: () => ({
        active_tab: 'loginTab',
        logo: '',
    }),
    created() {
        this.logo = require('../assets/StorNext_Icon.png');
    },
};
</script>

<style scoped>
html {
  overflow-y: auto;
}

.logo {
  display: block;
}

.logo-text {
    font-size: 50px;
    margin-right: 10px;
}
</style>
