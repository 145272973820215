import LicensingApi from '../services/api/Licensing';
import LicenseTransformer from '@/transformers/LicenseTransformer';
import LicenseStateInterface from '@/interfaces/LicenseStateInterface';

const LicenseModule = {
    namespaced: true,
    state: {
        licenses: [],
        authorizedCoeff: 85,
        configuredCoeff: 85,
    },

    mutations: {
        setLicenses: (state: LicenseStateInterface, licenses: any[]) => {
            state.licenses = licenses;
        },

        setAuthorizedCoeff: (state: LicenseStateInterface, coeff: number) => {
            state.authorizedCoeff = coeff;
        },

        setConfiguredCoeff: (state: LicenseStateInterface, coeff: number) => {
            state.configuredCoeff = coeff;
        },
    },

    getters: {
        getLicenses: (state: LicenseStateInterface): any[] => state.licenses,

        getAuthorizedCoeff: (state: LicenseStateInterface): number => state.authorizedCoeff,

        getConfiguredCoeff: (state: LicenseStateInterface): number => state.configuredCoeff,
    },

    actions: {
        getLicenses: (
            { commit }: { commit: (commit: string, licenses: any[]) => void }, payload: { isSummary: boolean },
        ) => new Promise((resolve, reject) => {
            LicensingApi.getLicensingReports().then(response => {
                const licenses: any[] = LicenseTransformer.getTransformer(response.data.report, payload.isSummary);

                commit('setLicenses',  licenses);

                resolve(licenses);
            }).catch(error => {
                reject(error);
            });
        }),

        getLicensesBySystemID: (
            { commit }: { commit: (commit: string, licenses: any[]) => void },
            payload: { isSummary: boolean; systemID: string },
        ) => new Promise((resolve, reject) => {
            LicensingApi.getLicensingReportBySystemID(payload.systemID).then(response => {
                const licenses: any[] = LicenseTransformer.getTransformer([response.data.report], payload.isSummary);

                commit('setLicenses',  licenses);

                resolve(licenses);
            }).catch(error => {
                reject(error);
            });
        }),

        getApproachingCoefficients: ({ commit }: { commit: (commit: string, coeff: number) => void }) => {
            LicensingApi.getApproachingCoefficients().then(response => {
                const { usedCapacityToAuth, usedCapacityToConf } = response.data.coefficients;

                commit('setAuthorizedCoeff', usedCapacityToAuth);
                commit('setConfiguredCoeff', usedCapacityToConf);
            }).catch(error => {
                console.error(error);
            });
        },
    },
};

export default LicenseModule;
