export const LEFT_MENU = [
    {
        title: 'Systems',
        icon: 'mdi-server',
        items: [
            {
                title: 'All',
                icon: 'mdi-checkbox-multiple-marked',
                link: 'home',
                tooltip: {
                    text: 'All',
                    enabled: () => false,
                },
            },
            {
                title: 'Connect',
                icon: 'mdi-transit-connection-variant',
                link: 'connect',
                tooltip: {
                    text: 'Connect',
                    enabled: () => false,
                },
            },
            {
                title: 'Appliances',
                icon: 'mdi-dns',
                link: 'appliance',
                tooltip: {
                    text: 'Appliances',
                    enabled: () => false,
                },
            },
            {
                title: 'StorNext Software',
                icon: 'mdi-refresh',
                link: 'stornext-software',
                tooltip: {
                    text: 'StoreNext Software',
                    enabled: () => false,
                },
            },
        ],
        tooltip: {
            text: 'Systems',
            enabled: () => false,
        },
    },
    {
        title: 'Licensing Report',
        icon: 'mdi-shield',
        link: 'licensing-report',
        tooltip: {
            text: 'Licensing Report',
            enabled: () => false,
        },
    },
    {
        title: 'Users Management',
        icon: 'mdi-account-group',
        link: 'users', isVisible: false,
        tooltip: {
            text: 'Users Management',
            enabled: () => false,
        },
        roles: ['ROLE_ADMIN', 'ROLE_LICENSE_ADMIN'],
    },
    {
        title: 'UUI Downloads',
        icon: 'mdi-download',
        link: 'usui-downloads',
        isVisible: true,
        tooltip: {
            text: 'UUI Downloads',
            enabled: () => false,
        },
    },
    {
        title: 'Download Metrics',
        icon: 'mdi-passport-biometric',
        link: 'download-metrics',
        isVisible: true,
        tooltip: {
            text: 'Download Metrics',
            enabled: () => false,
        },
        roles: ['ROLE_ADMIN', 'ROLE_LICENSE_ADMIN'],
    },
];

export const POP_MENU = [
    { title: 'Profile', icon: 'mdi-account', link: '/profile' },
    { title: 'Manage Delegate Users', icon: 'mdi-account-group', link: 'delegate' },
    { title: 'Change Password', icon: 'mdi-key', link: '/account' },
    { title: 'Logout', icon: 'mdi-logout', link: '/logout' },
];
