import axios from 'axios';
import store from '@/store';

export default {
    registration: (
        parameters: {
            firstName: string; lastName: string; email: string; password: string;
            phone: string; timeZone: string; companyName: string; hourFormat: number; subscribed: boolean;
        },
    ) => {
        const api = axios.create({
            withCredentials: true,
        });

        return api.post(
            store.getters['siteConfigState/getBackend'] + '/api/app-store/v1/register',
            parameters,
            { headers: { 'Content-Type': 'application/json' } },
        );
    },
};
