




















import Vue from 'vue';
import Profile from '@/components/Profile.vue';
import { Component } from 'vue-property-decorator';

@Component({
    components: {
        Profile,
    },
})
class AppHeader extends Vue {
    public name: string;
    public helpUrl: string;

    public constructor() {
        super();
        this.name = 'AppHeader';
        this.helpUrl = process.env.VUE_APP_HELP_URL;
    }

    public get logo(): string {
        return this.$store.getters['browserConfigState/getLogo'];
    }
}

export default AppHeader;
